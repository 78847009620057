import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

type Props = {
  currentRefinement: Number,
  nbPages: Number,
  refine: Function,
  onPageClick: Function,
  pageType: String,
};

const Pagination = ({ currentRefinement, nbPages, refine, onPageClick, pageType }: Props) => {
  React.useEffect(() => {
    refine(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  const renderButton = (label, page, style) => (
    <IconButton
      key={`button-page-${label}`}
      style={style}
      onClick={event => {
        event.preventDefault();
        refine(page);
        onPageClick();
      }}
    >
      {label}
    </IconButton>
  );

  const baseStyle = {
    borderRadius: '100%',
    fontSize: '1em',
    color: 'black',
    margin: '5px',
    height: '30px',
    width: '30px',
  };

  return (
    <Grid style={{ textAlign: 'center', paddingTop: 16 }}>
      {nbPages > 0 && (
        <>
          {renderButton('<', currentRefinement > 1 ? currentRefinement - 1 : 1, baseStyle)}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;

            const style = {
              ...baseStyle,
              fontWeight: currentRefinement === page ? 'bold' : '',
              color: currentRefinement === page ? 'white' : 'black',
              backgroundColor: currentRefinement === page ? 'black' : 'transparent',
            };
            if (page < currentRefinement - 1) {
              if (page === 1) {
                return renderButton(page, page, style);
              }
              if (page === currentRefinement - 2) {
                return '...';
              }
            } else if (page > currentRefinement + 1) {
              if (page === nbPages) {
                return renderButton(page, page, style);
              }
              if (page === currentRefinement + 2) {
                return '...';
              }
            } else return renderButton(page, page, style);
            return null;
          })}
          {renderButton(
            '>',
            currentRefinement < nbPages ? currentRefinement + 1 : nbPages,
            baseStyle,
          )}
        </>
      )}
    </Grid>
  );
};

const CustomPagination = connectPagination(Pagination);

export default CustomPagination;
