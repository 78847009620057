import React, { useState, useRef } from 'react';
import 'moment/locale/fr';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import orderBy from 'lodash/orderBy';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Accordion, AccordionSummary, Hidden } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Metrics } from '../../../Themes';
import LayoutStyles, { isMobile } from '../../Layout/Styles/LayoutStyles';
import CustomGeoSearch from './CustomGeoSearch';
import CustomRefinementList from './CustomRefinementList';
import CustomPagination from './CustomPagination';
import { CustomStateResults } from './CustomHits';
import {
  searchClient,
  FILTERING_ATTRIBUTES,
  formatType,
  sluggifyType,
} from '../../../Services/AlgoliaHelper';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import EmptyList from '../EmptyList';
import { getCityDescription, getActivityDescription } from '../../../Services/SeoHelper';
import Routes from '../../../Routing/Routes';
import SearchResultBreadcrumb from '../Breadcrumbs/SearchResultBreadcrumb';

type Props = {
  request: Object,
  position: Object,
  typeAttributes: Object,
  tabs: Array,
  bbox: Array,
  showHighlightedOrga: Boolean,
};

const useStyles = makeStyles(theme => ({
  layoutDiff: {
    ...LayoutStyles.fullWidthLayout,
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  leftContent: {
    padding: 0,
  },
  rightContent: {},
  geoSearch: {
    padding: '0',
    paddingBottom: theme.spacing(3),
  },
  scroll: {
    display: 'block',
    position: 'sticky',
    top: '-250px',
    bottom: '0',
  },
  heroUnit: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      paddingTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
    },
  },
  tabs: {
    position: 'sticky',
    top: '56px',
    backgroundColor: 'white',
    zIndex: '1010',
  },
  tab: {
    [theme.breakpoints.only('xs')]: {
      textTransform: 'none',
      '&>*': {
        fontSize: '10px',
      },
    },
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    paddingTop: theme.spacing(1),
    fontSize: '1.2rem',
    fontWeight: '600',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  description: {
    marginRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  activityDescription: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

function InstantSearchResults({
  request,
  position,
  typeAttributes,
  tabs,
  bbox,
  showHighlightedOrga,
}: Props) {
  const classes = useStyles();
  const hitsRef = useRef([]);
  const { t } = useTranslation();
  const [selectedHit, setSelectedHit] = useState();
  const hasLeftMenu = request.hasMap || request.refinementAttributes;
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const {
    params: { type_slug: typeSlug },
  } = match;
  const type = formatType(typeSlug);
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    serviceSlug: queryParam.get('service'),
    city: queryParam.get('city'),
    pathology: queryParam.get(FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE) || '',
    age: queryParam.get('age') || null,
    expertise: queryParam.get(FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE) || null,
  };
  const [expanded, setExpanded] = React.useState(!isMobile);
  const cityDescription = getCityDescription(Routes.currentNamespace(), type, urlParams.city);
  const activityDescription = getActivityDescription(
    Routes.currentNamespace(),
    urlParams.expertise,
  );

  const onTabValueChange = (event, newValue) => {
    let { pathname } = location;
    const formattedValue = sluggifyType(newValue);

    if (!type) {
      pathname = `/recherche/${formattedValue}${pathname}`;
    } else if (formattedValue !== typeSlug) {
      pathname = pathname.replace(typeSlug, formattedValue);
    }

    const historyToPush = `${pathname}${location.search}`;
    history.push(historyToPush);
  };

  const onCardClick = hit => {
    setSelectedHit(hit);
  };

  const onMarkerClick = hit => {
    Object.keys(hitsRef.current).forEach(key => {
      if (hitsRef.current[key]?.current?.style) {
        hitsRef.current[key].current.style.transform = null;
      }
    });
    if (hit && hitsRef.current[hit.objectID].current) {
      hitsRef.current[hit.objectID].current.style.transform = 'scale(1.03)';
      if (window) {
        window.scrollTo({
          top: hitsRef.current[hit.objectID].current.offsetTop + Metrics.navBarHeight - 200,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  const onPageClick = () => {
    setSelectedHit(null);
    Object.keys(hitsRef.current).forEach(key => {
      if (hitsRef.current[key]?.current?.style) {
        hitsRef.current[key].current.style.transform = null;
      }
    });
    if (window) {
      window.scrollTo({
        top: Metrics.navBarHeight + 55 + 110,
        left: 0,
      });
    }
  };

  const orderRefinementItems = (items, attribute) => {
    const newItems = items.slice();
    if (attribute.attribute === FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE) {
      const editedItems = orderBy(newItems, 'label', 'asc').filter(
        item => item.label !== 'Personne âgée',
      );
      const itemToAdd = newItems.find(item => item.label === 'Personne âgée');
      if (itemToAdd) {
        editedItems.unshift(itemToAdd);
      }

      return editedItems;
    }
    if (attribute.attribute === FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE) {
      const newLabelItems = newItems.map(item => {
        return {
          count: item.count,
          isRefined: item.isRefined,
          label: item.label,
          value: item.value,
          icon: getExpertiseStyle({ slug: item.label }).icon,
          labelToShow: t(getExpertiseStyle({ slug: item.label }).label),
          level: getExpertiseStyle({ slug: item.label }).level,
        };
      });

      return orderBy(newLabelItems, ['level', 'label'], 'asc');
    }
    if (
      attribute.attribute === FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE
    ) {
      const newLabelItems = newItems.map(item => {
        return {
          count: item.count,
          isRefined: item.isRefined,
          label: item.label,
          value: item.value,
          labelToShow: t(getExpertiseStyle({ slug: item.label }).label),
          level: getExpertiseStyle({ slug: item.label }).level,
        };
      });
      return orderBy(newLabelItems, ['level', 'label'], 'asc');
    }
    if (attribute.attribute === FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE) {
      const newLabelItems = newItems.map(item => {
        return {
          count: item.count,
          isRefined: item.isRefined,
          label: item.label,
          value: item.value,
          labelToShow: t(`APP.ORGANISATION.SETTINGS.GENERAL.TYPE.${item.label}`),
        };
      });

      return orderBy(newLabelItems, ['level', 'label'], 'asc');
    }

    return orderBy(orderBy(newItems, 'label', 'asc'), 'count', 'desc');
  };

  const getDefaultRefinement = attribute => {
    const param = queryParam.get(attribute.attribute) || null;
    if (!param) return [];
    return param.split('|');
  };

  const renderTitle = () => (
    <>
      {(!urlParams.city || !typeAttributes[type].showCityInfo) && (
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          className={classes.title}
          style={request.hasMap ? { paddingLeft: 0 } : { marginLeft: 24 }}
        >
          {t(typeAttributes[type].title)}
          {urlParams.serviceSlug && typeAttributes[type].showKeywordInfo && (
            <>
              <Grid className={classes.subtitle}>
                {t('APP.SEARCH_RESULT_PAGE.ABOUT_QUERY', {
                  query: urlParams.serviceSlug,
                })}
              </Grid>
            </>
          )}
        </Typography>
      )}
      {urlParams.city && typeAttributes[type].showCityInfo && (
        <Grid className={classes.title}>
          <Typography variant="h3" component="h1" gutterBottom>
            {t(typeAttributes[type].titleWithCity || typeAttributes[type].title)}{' '}
            {t(typeAttributes[type].aroundCityPrefix)} {urlParams.city}
            {urlParams.serviceSlug && typeAttributes[type].showKeywordInfo && (
              <>
                <Grid className={classes.subtitle}>
                  {t('APP.SEARCH_RESULT_PAGE.ABOUT_QUERY', {
                    query: urlParams.serviceSlug,
                  })}
                </Grid>
              </>
            )}
          </Typography>
          {cityDescription && (
            <Typography gutterBottom component="h2" className={classes.description}>
              {cityDescription}
            </Typography>
          )}
          {typeAttributes[type].showPathologyInfo &&
            ((urlParams.pathology || urlParams.age) && typeAttributes[type].isFilteringEnough ? (
              <Grid container>
                <Typography gutterBottom component="h2" className={classes.marginRight}>
                  {t('APP.SEARCH_RESULT_PAGE.ABOUT_PATHOLOGY')}
                </Typography>
                {urlParams.pathology && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={urlParams.pathology}
                    className={classes.marginRight}
                  />
                )}
                {urlParams.age && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={urlParams.age === '-60' ? t('NOT_OLD') : t('OLD')}
                    className={classes.marginRight}
                  />
                )}
              </Grid>
            ) : (
              <EmptyList id="APP.SEARCH_RESULT_PAGE.UNSELECTED_PATHOLOGY" level="info" />
            ))}
        </Grid>
      )}
      {type === 'twigs' && activityDescription && (
        <Typography
          gutterBottom
          component="h2"
          className={classes.activityDescription}
          dangerouslySetInnerHTML={{ __html: activityDescription }}
        />
      )}
    </>
  );

  return (
    <>
      {tabs?.length > 1 && (
        <Grid className={classes.tabs}>
          <Paper square>
            <Tabs
              value={type}
              indicatorColor="primary"
              textColor="primary"
              onChange={onTabValueChange}
              scrollButtons="auto"
              variant="fullWidth"
            >
              {tabs.map(tab => (
                <Tab key={tab.label} label={tab.label} value={tab.value} className={classes.tab} />
              ))}
            </Tabs>
          </Paper>
        </Grid>
      )}
      <Grid className={classes.layout}>
        <Grid className={classes.heroUnit}>
          {!request.hasMap && renderTitle()}

          {request.indexName && (
            <InstantSearch
              searchClient={searchClient}
              indexName={request.indexName}
              key={request.indexName}
            >
              <Grid container spacing={4} className={classes.layoutDiff}>
                {hasLeftMenu && (
                  <Grid item md={4} xs={12} className={classes.leftContent}>
                    <Grid container className={classes.scroll}>
                      {request.hasMap && (
                        <CustomGeoSearch
                          className={classes.geoSearch}
                          query={urlParams.serviceSlug}
                          initialPosition={
                            position
                              ? {
                                  lat: position.lat,
                                  lng: position.lng,
                                }
                              : null
                          }
                          onMarkerClick={onMarkerClick}
                          selectedHit={selectedHit}
                          zoom={request.mapZoom}
                        />
                      )}
                      {request?.refinementAttributes?.length > 0 && (
                        <Hidden mdUp>
                          <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>{t('FILTER')}</Typography>
                            </AccordionSummary>
                          </Accordion>
                        </Hidden>
                      )}
                      {request?.refinementAttributes &&
                        expanded &&
                        request?.refinementAttributes?.map(attribute => (
                          <Grid key={attribute.label}>
                            <CustomRefinementList
                              transformItems={items => orderRefinementItems(items, attribute)}
                              attribute={attribute.attribute}
                              label={attribute.label}
                              defaultRefinement={getDefaultRefinement(attribute)}
                              limit={attribute.limit || 20}
                              showMore
                              showMoreButtonLimit={attribute.showMoreLimit || 10}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item md={hasLeftMenu ? 8 : 12} xs={12} className={classes.rightContent}>
                  <Grid container>
                    <CustomStateResults>
                      <request.HitsDisplay
                        refProp={hitsRef}
                        query={urlParams.serviceSlug}
                        onCardClick={onCardClick}
                        bbox={bbox}
                        titleNode={request.hasMap ? renderTitle() : null}
                        {...request.cardSettings}
                        isFilteringEnough={typeAttributes[type].isFilteringEnough}
                        showHighlightedOrga={showHighlightedOrga}
                      />
                      <Configure {...request.configureSettings} />
                      <CustomPagination onPageClick={onPageClick} pageType={request.indexName} />
                    </CustomStateResults>
                  </Grid>
                </Grid>
              </Grid>
            </InstantSearch>
          )}
          {!request.indexName && request.FallBackComponent && <request.FallBackComponent />}
        </Grid>
      </Grid>
      <SearchResultBreadcrumb />
    </>
  );
}

export default InstantSearchResults;
