import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import StarIcon from '@mui/icons-material/Star';
import { CardContent, CardMedia, Divider } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import Routes from '../../../Routing/Routes';
import { Images } from '../../../Themes';
import ImageWithFallback from '../../Shared/ImageWithFallback';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    position: 'relative',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  cardMedia: {
    flexShrink: 0,
    borderRadius: 4,
    height: 258,
    width: 230,
    [theme.breakpoints.only('xs')]: {
      height: 150,
      width: '100%',
    },
  },
  textContainer: {
    height: 266,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: theme.spacing(2, 0, 2, 2),
    [theme.breakpoints.only('xs')]: {
      height: 'auto',
      padding: theme.spacing(2, 0, 0),
    },
  },
  chipContainer: {
    margin: theme.spacing(2, 0, 1),
    maxHeight: 45,
    overflow: 'hidden',
  },
  chip: {
    padding: 0,
    marginRight: 4,
    marginBottom: 4,
    fontSize: 13,
    height: 20,
    backgroundColor: '#efefef',
  },
  placeIcon: {
    marginRight: theme.spacing(0.5),
  },
  link: {
    textTransform: 'none',
  },
  name: {
    textTransform: 'none',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
  },
  description: {
    marginTop: theme.spacing(2),
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
  address: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
  },
  divider: {
    margin: theme.spacing(2, 0, 1),
  },
  absoluteBtn: {
    position: 'absolute',
    bottom: 32,
    right: 16,
  },
  absoluteChip: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '16px 0 0 16px',
    color: 'white',
    [theme.breakpoints.only('xs')]: {
      top: 16,
      right: 16,
    },
  },
}));

type Props = {
  organisation: Number,
  bannerUrl: String,
  bannerBackgroundColor: String,
  holdingName: String,
  isOnline: Object,
  description: String,
  // city: String,
  elevation: Number,
  onClick: Function,
  backgroundColor: String,
};

const CardOrganisationMain = ({
  bannerUrl,
  bannerBackgroundColor,
  holdingName,
  isOnline,
  organisation,
  description,
  // city,
  elevation,
  onClick,
  backgroundColor: bckColor,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const leagueSlug = Routes.currentLeague().slug;

  let serviceMore = organisation.services?.length;
  serviceMore -= organisation.services?.length >= 7 ? 7 : organisation.services?.length;
  const showMapBtn = isOnline && leagueSlug !== 'una' && organisation.formatted_address;
  const isReferent = bckColor && !organisation.isNotReferenced;

  const renderActionArea = () => {
    return (
      <CardContent className={classes.cardContent}>
        {isReferent && (
          <Chip
            label="Votre structure référente"
            icon={<StarIcon style={{ color: 'white' }} />}
            size="small"
            style={{ backgroundColor: bckColor }}
            className={classes.absoluteChip}
          />
        )}
        <ImageWithFallback
          src={bannerUrl}
          successRenderer={
            <CardMedia
              component="img"
              image={bannerUrl}
              alt={holdingName}
              className={classes.cardMedia}
              style={{ backgroundColor: bannerBackgroundColor || grey[200] }}
            />
          }
          errorRenderer={
            <CardMedia
              component="img"
              image={Images.app.team}
              alt={holdingName}
              className={classes.cardMedia}
              style={{ backgroundColor: bannerBackgroundColor || grey[200] }}
            />
          }
        />

        <Grid container className={classes.textContainer}>
          <Grid>
            <Tooltip title={holdingName} placement="top">
              <Typography variant="h4" component="h2" className={classes.name}>
                {holdingName}
              </Typography>
            </Tooltip>
            <Typography variant="h5" component="h3" className={classes.name}>
              {organisation.name}
            </Typography>
            <Typography variant="caption" component="div">
              {organisation?.establishment?.short_name}
            </Typography>
            {organisation.isNotReferenced && organisation.holding?.phone && (
              <Typography variant="caption" component="div">
                {organisation.holding?.phone}
              </Typography>
            )}
            {description && (
              <Typography variant="body2" component="div" className={classes.description}>
                {description.replace(/<[^>]*>/g, '')}
              </Typography>
            )}
            {organisation.isNotReferenced && (
              <Hidden only={['xs']}>
                <Button
                  href="mailto:federation@soutenirlesaidants.fr?cc=hello@wello.fr"
                  className={classes.link}
                >
                  {t('APP.ORGANISATION.HOME.SERVICES.CREATE_ORGA_HOME')}
                </Button>
              </Hidden>
            )}
            {organisation.services && (
              <Grid container className={classes.chipContainer}>
                {organisation.services?.map((orgaService, i) => (
                  <div key={`service-${orgaService.service.id}`}>
                    {i < 7 && <Chip className={classes.chip} label={orgaService.service.label} />}
                  </div>
                ))}
                {serviceMore > 0 && <Chip className={classes.chip} label="..." />}
              </Grid>
            )}
          </Grid>
          {organisation.formatted_address && (
            <Grid>
              <Divider className={classes.divider} />

              <Grid style={{ height: 28, flexWrap: 'nowrap' }} container alignItems="center">
                <FmdGoodIcon fontSize="small" className={classes.placeIcon} />
                <Typography variant="caption" component="div" className={classes.address}>
                  {organisation.formatted_address}
                </Typography>
                {showMapBtn && (
                  <Hidden only={'xs'}>
                    <Grid style={{ width: 190, flexShrink: 0 }} />
                  </Hidden>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    );
  };

  return (
    <Card
      className={classes.card}
      elevation={elevation || 1}
      style={isReferent && { boxShadow: '0px 0px 34px 0px rgba(127, 37, 167, 0.25)' }}
    >
      {!organisation.isNotReferenced && (
        <CardActionArea component={Link} to={Routes.organisationHomePath(organisation)}>
          {renderActionArea()}
        </CardActionArea>
      )}
      {organisation.isNotReferenced && renderActionArea()}
      {showMapBtn && (
        <Hidden only={'xs'}>
          <Button size="small" onClick={onClick} className={classes.absoluteBtn}>
            {t('APP.ORGANISATION.HOME.SERVICES.MAP')}
          </Button>
        </Hidden>
      )}
      {/* {!organisation.isNotReferenced && leagueSlug === 'una' && isOnline && (
        <Hidden only={'xs'}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.absoluteBtn}
            href={`/una-national/una-administratif/quotation/cities/${city}`}
          >
            {t('APP.ORGANISATION.HOME.SERVICES.ACTION')}
          </Button>
        </Hidden>
      )} */}
    </Card>
  );
};

export default CardOrganisationMain;
