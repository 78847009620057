export const getCityDescription = (leagueNamespace, type, city) => {
  if (!city || !type) return null;

  switch (leagueNamespace) {
    case 'SLA':
      switch (type) {
        case 'organisations':
          return SLA_CITY_DESCRIPTION[city];
        default:
          return null;
      }
    default:
      return null;
  }
};

export const getActivityDescription = (leagueNamespace, expertise) => {
  switch (leagueNamespace) {
    case 'SLA':
      return SLA_ACTIVITY_DESCRIPTION[expertise] || SLA_ACTIVITY_DESCRIPTION['default'];
    default:
      return null;
  }
};

const SLA_CITY_DESCRIPTION = {
  Lille:
    'Vous habitez à Lille et vous accompagnez un proche âgé, malade ou en situation de handicap. Faites-vous aider par la maison des aidants proche de chez vous.',
  Paris:
    'Les plateformes de répit de Paris ont pour mission de vous accompagner dans vos démarches et vous soulager dans l’accompagnement de votre proche.',
  Toulon:
    'Vous apportez de l’aide à un proche âgé, malade ou en situation de handicap, pour ses actes de la vie quotidienne à Toulon, trouvez des solutions grâce aux plateformes de répit.',
  Nantes:
    'Trouvez du soutien, des conseils et de l’information à Nantes grâce aux plateformes de répit.',
  Toulouse:
    'Les plateformes de répit de Toulouse ont pour mission de vous accompagner dans vos démarches et vous soulager dans l’accompagnement de votre proche âgé, malade ou en situation de handicap.',
  Poitiers:
    'Vous avez besoin d’accompagnement à Poitiers pour prendre soin de votre proche âgé, malade ou en situation de handicap. Prenez contact avec une plateforme de répit.',
  Reims:
    'Vous cherchez des solutions d’aide à Reims pour votre proche âgé, malade ou en situation de handicap. Les plateformes de répit peuvent vous aider.',
  Montpellier:
    'Vous habitez Montpellier et êtes un aidant familial. Trouvez de l’aide auprès de la plateforme de répit.',
  Marseille:
    'Vous avez besoin d’accompagnement à Marseille. Découvrez les solutions proposées par les plateformes de répit.',
  Strasbourg:
    'Vous résidez à Strasbourg et cherchez des solutions pour mieux accompagner votre proche âgé, malade ou en situation de handicap. Faites appel aux plateformes de répit de votre territoire.',
  Lyon: 'Les plateformes de répit à Lyon vous soutiennent pour accompagner au mieux votre proche âgé, malade ou en situation de handicap.',
  Nice: 'Trouvez de l’aide auprès des plateformes de répit de Nice pour accompagner votre proche âgé, malade ou en situation de handicap.',
  Bordeaux:
    'Trouvez de l’aide auprès des plateformes de répit de Bordeaux pour accompagner votre proche âgé, malade ou en situation de handicap.',
  Rennes:
    'Vous habitez à Rennes et accompagnez un proche. Découvrez les solutions proposées par les plateformes de répit.',
  'Saint-Étienne':
    'Vous cherchez des solutions pour mieux accompagner votre proche. Faites-vous accompagner par la plateforme de répit de Saint-Étienne.',
  'Le Havre':
    'Vous prenez soin d’un proche et vous habitez au Havre, les plateformes de répit ont pour mission de vos aider.',
};

const SLA_ACTIVITY_DESCRIPTION = {
  default:
    'Trouvez des activités proches de chez vous en fonction de vos centres d’intérêts. Les plateformes de répit vous proposent des activités pour les aidants, accompagnés ou non de votre proche.',
  'activites-creatives':
    'Art-thérapie, écriture, peinture, couture, poterie, dessin et bien plus. Retrouvez d’autres aidants lors d’activités créatives !',
  'activites-musicales':
    'Participez à des activités musicales en compagnie d’autres aidants : concerts, chorale, cours de chant, cours de musique, etc.',
  'activites-sportives':
    'Gym douce, sport adapté, danse, yoga, piscine. Venez rejoindre d’autres aidants et prendre soin de vous lors d’activités sportives.',
  'maintien-en-forme':
    'Restez en forme grâce à des balades, du yoga, des étirements, de la gym douce en compagnie d’autres aidants et parfois de votre proche.',
  'rencontres-conviviales':
    'Partagez votre quotidien et venez discuter avec d’autres aidants lors de rencontres conviviales : café échanges, sorties au restaurant, et bien plus.',
  jeux: 'Vous voulez jouer à des jeux de société, au scrabble, à la belote, ou même au bridge ? Venez rejoindre d’autres aidants et vous divertir.',
  'sorties-culturelles':
    'Venez rejoindre d’autres aidants pour participer à des visites de musée, aller découvrir des expositions, partir en excursion, et bien plus.',
  'ateliers-de-prevention':
    "Participez à des ateliers de prévention avec ou sans votre proche : ateliers mémoire, ateliers cuisine, et bien d'autres.",
  'formations-2': `Participez à des formations créées pour les aidants afin d’être guidés dans votre rôle et d’en apprendre davantage sur la pathologie de votre proche. Retrouvez aussi nos formations en ligne <a href="https://www.soutenirlesaidants.fr/recherche/formations">ici</a>.`,
  informations:
    'Profitez de temps d’échange lors de permanences dédiées afin d’avoir accès à des informations spécialisées pour les aidants.',
  'ateliers-numeriques':
    'Formez-vous à l’utilisation du numérique : outils bureautique, tablette, smartphone.',
  'detente-bien-etre':
    'Prenez soin de vous lors de séances de sophrologie, de yoga, d’aromathérapie, tout en étant accompagnés de professionnels spécialistes de l’aide aux aidants.',
  'groupes-de-parole-2':
    'Partagez votre expérience d’aidant avec d’autres aidants lors de groupes de parole.',
};
