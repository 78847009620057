export const HOLDING_VISIBILITIES = [
  {
    label: 'APP.ARTICLES.VISIBILITY.PUBLIC',
    value: 'public',
  },
  {
    label: 'APP.ARTICLES.VISIBILITY.PRIVATE_ALL',
    value: 'private_all',
  },
  {
    label: 'APP.ARTICLES.VISIBILITY.PRIVATE_PRO_VOLUNTEER',
    value: 'private_pro_volunteer',
  },
  {
    label: 'APP.ARTICLES.VISIBILITY.PRIVATE_PRO',
    value: 'private_pro',
  },
];

export const LEAGUE_VISIBILITIES = [
  {
    label: 'APP.ARTICLES.VISIBILITY.PUBLIC',
    value: 'public',
  },
  {
    label: 'APP.ARTICLES.VISIBILITY.PRIVATE_PREMIUM',
    value: 'private_premium',
  },
];

export const COMMUNITIY_VISIBILITIES = [
  {
    label: 'APP.COMMUNITY_ARTICLES.VISIBILITY.PRIVATE_ALL',
    value: 'private_all',
  },
];

export const getWithoutHtml = content => {
  const tagReg = new RegExp('<.[^>]*>', 'gi');
  const spaceReg = new RegExp('&nbsp;', 'gi');
  return content.replace(tagReg, '').replace(spaceReg, '');
};
