import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import IconAccessible from '@material-ui/icons/Accessible';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Popover from '@material-ui/core/Popover';
import { getTripPrice } from '../../../Services/DataHelper';

import Routes from '../../../Routing/Routes';
import { isClientSide } from '../../../Config/ServerConfig';
import { getCdnUrl } from '../../../Services/ImageResizer';
import Images from '../../../Themes/Images';

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
  },
  avatar: {
    margin: '-56px auto 0px auto',
    width: 80,
    height: 80,
    border: '3px solid #fff',
  },
  header: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    minHeight: 55,
  },
  height: {
    height: 40,
    paddingTop: 8,
  },
  textColor: {
    color: theme.mormal.primary.main,
  },
  icons: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing(0.5),
  },
  contentDisabled: {
    backgroundColor: theme.palette.grey[200],
  },
  actions: {
    marginTop: '10px',
  },
  disabledAction: {
    padding: theme.spacing(2, 0),
  },
  priceDescription: {
    paddingTop: theme.spacing(0.5),
    fontSize: '0.6rem',
  },
  organisation_subtitle: {
    minHeight: '55px',
  },
  avatarTransport: {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  organisation: Number,
  bannerUrl: String,
  avatarUrl: String,
  holdingName: String,
  name: String,
  description: String,
  disabled: String,
  withoutLink: Boolean,
  tripInfo: Object,
  hidePrice: Boolean,
  transportType: String,
};

const CardOrganisationTransport = ({
  bannerUrl,
  avatarUrl,
  name,
  holdingName,
  disabled,
  description,
  organisation,
  withoutLink,
  tripInfo,
  hidePrice,
  transportType,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const selectedServices = organisation?.services
    ?.map(os => os.service)
    .filter(serv => serv.service_category.slug === 'transport');

  let service = null;
  if (
    selectedServices?.length > 0 &&
    organisation?.slug === 'familles-rurales-avesnois-mormal' &&
    organisation?.holding_slug === 'familles-rurales'
  ) {
    service = selectedServices.find(s => s.id === 1305);
  } else if (selectedServices?.length > 0) service = selectedServices[0];

  const price = getTripPrice(service, tripInfo, null, null);

  const transportTypes = {
    solidary: {
      avatar: <Avatar src={Images.mormal.choice_card_1} className={classes.avatarTransport} />,
      title: t('MORMAL.SEARCH_RESULT_PAGE.SOLIDARY_TITLE'),
      subtitle: t('MORMAL.SEARCH_RESULT_PAGE.SOLIDARY_SUBTITLE'),
    },
    sap: {
      avatar: <Avatar src={Images.mormal.choice_card_2} className={classes.avatarTransport} />,
      title: t('MORMAL.SEARCH_RESULT_PAGE.SAP_TITLE'),
      subtitle: t('MORMAL.SEARCH_RESULT_PAGE.SAP_SUBTITLE'),
    },
    ambulance: {
      avatar: <Avatar src={Images.mormal.choice_card_4} className={classes.avatarTransport} />,
      title: t('MORMAL.SEARCH_RESULT_PAGE.OTHER2_TITLE'),
      subtitle: t('MORMAL.SEARCH_RESULT_PAGE.OTHER2_SUBTITLE'),
    },
    cab: {
      avatar: <Avatar src={Images.mormal.choice_card_3} className={classes.avatarTransport} />,
      title: t('MORMAL.SEARCH_RESULT_PAGE.OTHER_TITLE'),
      subtitle: t('MORMAL.SEARCH_RESULT_PAGE.OTHER_SUBTITLE'),
    },
  };

  return (
    <>
      {service && (
        <Card itemScope>
          <CardMedia
            component="img"
            className={classes.media}
            height="140"
            src={getCdnUrl(bannerUrl, 280, 140) || Images.wello.orga_empty_banner}
            style={{ opacity: disabled ? 0.3 : 1 }}
          />
          <CardContent
            className={disabled && classes.contentDisabled}
            style={{ textAlign: 'center', paddingBottom: '10px' }}
          >
            {withoutLink && (
              <Avatar src={avatarUrl || Images.logo.wello_circle} className={classes.avatar} />
            )}
            {!withoutLink && (
              <Avatar
                src={avatarUrl || Images.logo.wello_circle}
                className={classes.avatar}
                component={Link}
                to={Routes.organisationHomePath(organisation)}
                style={{ opacity: disabled ? 0.75 : 1 }}
              />
            )}
            <Typography variant="h4" component="h3" className={classes.header} gutterBottom>
              {holdingName}
            </Typography>
            <Typography variant="h6" component="h3" className={classes.header} gutterBottom>
              {name}
            </Typography>
            <Typography
              variant="body2"
              className={classes.description}
              color="textSecondary"
              gutterBottom
            >
              {description}
            </Typography>
            {transportType &&
              transportTypes[transportType] &&
              transportTypes[transportType].avatar && (
                <>
                  <Divider style={{ marginTop: 16, marginBottom: 8 }} />
                  <ListItem style={{ padding: 0, height: 92 }}>
                    <ListItemAvatar>{transportTypes[transportType].avatar}</ListItemAvatar>
                    <ListItemText
                      primary={transportTypes[transportType].title}
                      secondary={transportTypes[transportType].subtitle}
                      secondaryTypographyProps={{
                        variant: 'subtitle2',
                        fontSize: '0.8rem',
                        letterSpacing: '0.007em',
                        lineHeight: '1.5',
                      }}
                      style={{ marginLeft: -8 }}
                    />
                  </ListItem>

                  {/* <Typography variant="h5" component="h2" align="center">
                    
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: transportTypes[transportType].title }}
                    />
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    className={classes.organisation_subtitle}
                    dangerouslySetInnerHTML={{ __html: transportTypes[transportType].subtitle }}
                  /> */}
                  <Divider style={{ marginTop: 8 }} />
                </>
              )}
            <Grid className={classes.height}>
              {service?.adapted_car && (
                <Typography variant="subtitle1" className={classes.textColor}>
                  <IconAccessible className={classes.icons} />
                  {t('MORMAL.SEARCH_RESULT_PAGE.ADAPTED_CAR')}
                </Typography>
              )}
            </Grid>
            <Grid className={classes.height}>
              {service?.max_seat && (
                <Typography variant="subtitle1" className={classes.textColor}>
                  {t('MORMAL.SEARCH_RESULT_PAGE.MAX_SEAT', { max_seat: service?.max_seat })}
                </Typography>
              )}
            </Grid>
            {isClientSide() && !hidePrice && (
              <>
                <Typography variant="h4" component="h3" className={classes.header} gutterBottom>
                  {t('MORMAL.SEARCH_RESULT_PAGE.ESTIMATED_PRICE')}
                </Typography>
                <Typography variant="h4" component="h3" className={classes.header} gutterBottom>
                  {price
                    ? t('MORMAL.SEARCH_RESULT_PAGE.SHOW_PRICE', { price: price.toFixed(2) })
                    : t('MORMAL.SEARCH_RESULT_PAGE.NO_PRICE')}
                </Typography>
              </>
            )}
            {!disabled && (
              <CardActions size="small" className={classes.actions}>
                <Button
                  variant="contained"
                  component={Link}
                  to={Routes.customQuotationPath(organisation, service)}
                  fullWidth
                >
                  {t('APP.ORGANISATION.HOME.SERVICES.ACTION')}
                </Button>
              </CardActions>
            )}
            {disabled && (
              <Typography
                variant="h6"
                component="div"
                className={[classes.actions, classes.disabledAction]}
              >
                {t(`APP.ORGANISATION.HOME.SERVICES.DISABLED.${disabled.toUpperCase()}`)}
              </Typography>
            )}
            {isClientSide() && service.price_description && (
              <Button
                aria-describedby="popover-price"
                onClick={event => setAnchorEl(event.target)}
                size="small"
                className={classes.priceDescription}
              >
                {t('MORMAL.SEARCH_RESULT_PAGE.PRICE_DETAIL')}
              </Button>
            )}
            <Popover
              id="popover-price"
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{ style: { padding: '20px', maxWidth: '400px' } }}
            >
              <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
                {service.price_description}
              </Typography>
            </Popover>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default CardOrganisationTransport;
