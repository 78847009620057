import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { FILTERING_ATTRIBUTES, wordifyType } from '../../../Services/AlgoliaHelper';
import Routes from '../../../Routing/Routes';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import { useTranslation } from 'react-i18next';
import Breadcrumb from './Breadcrumb';

const SearchResultBreadcrumb = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const {
    params: { type_slug: typeSlug },
  } = match;
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    serviceSlug: queryParam.get('service') || null,
    city: queryParam.get('city') || null,
    pathology: queryParam.get(FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE) || '',
    age: queryParam.get('age') || null,
    expertise: queryParam.get(FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE) || null,
    address: queryParam.get('address') || '',
    latitude: queryParam.get('lat') || null,
    longitude: queryParam.get('long') || null,
    bbox: queryParam.get('bbox') || null,
  };

  const rootName = Routes.currentLeague().rootName;
  let searchUrl = `/recherche/${typeSlug}`;

  const links = [
    { name: rootName, link: '/' },
    { name: wordifyType(typeSlug), link: searchUrl },
  ];

  if (urlParams.city && ['structures', 'activites'].includes(typeSlug)) {
    searchUrl += `?city=${urlParams.city}&address=${urlParams.address}&lat=${urlParams.latitude}&long=${urlParams.longitude}`;
    if (urlParams.bbox) searchUrl += `&bbox=${urlParams.bbox}`;

    links.push({
      name: urlParams.city,
      link: searchUrl,
    });
  }

  if (urlParams.serviceSlug && ['articles', 'communautes', 'formations'].includes(typeSlug)) {
    searchUrl += `${links.length > 2 ? '&' : '?'}service=${urlParams.serviceSlug}`;
    links.push({
      name: urlParams.serviceSlug,
      link: searchUrl,
    });
  }

  if (urlParams.pathology && typeSlug === 'structures') {
    searchUrl += `${links.length > 2 ? '&' : '?'}&${
      FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE
    }=${urlParams.pathology}`;

    links.push({
      name: urlParams.pathology,
      link: searchUrl,
    });
  }

  if (urlParams.age && typeSlug === 'structures') {
    searchUrl += `${links.length > 2 ? '&' : '?'}age=${urlParams.age}`;
    links.push({
      name: `${urlParams.age} ans`,
      link: searchUrl,
    });
  }

  if (urlParams.expertise && typeSlug === 'activites') {
    searchUrl += `${links.length > 2 ? '&' : '?'}${
      FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE
    }=${urlParams.expertise}`;
    const expertises = urlParams.expertise.split('|');
    links.push({
      name: `${t(getExpertiseStyle({ slug: expertises[0] }).label)}${
        expertises.length > 1 ? '...' : ''
      }`,
      link: searchUrl,
    });
  }

  const current = links[links.length - 1];
  const previous = links.slice(0, -1);

  return <Breadcrumb previousLinks={previous} currentLink={current} />;
};

export default SearchResultBreadcrumb;
