import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connectRefinementList } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { IconButton } from '@material-ui/core';

type Props = {
  items: Array,
  label: String,
  attribute: String,
  limit: number,
  showMore: boolean,
  showMoreButtonLimit: number,
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
    color: 'black',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  regular: {
    color: 'black',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  grid: {
    display: 'flex',
  },
  group: {
    marginLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
  label: {
    justifyContent: 'flex-start',
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const RefinementList = ({
  items,
  label,
  attribute,
  limit,
  showMore,
  showMoreButtonLimit,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [showAll, setShowAll] = React.useState(false);

  const itemsToShow = showAll ? items.slice(0, limit) : items.slice(0, showMoreButtonLimit);

  const getRefinedItems = item => {
    let refinedItems = items?.filter(it => it.isRefined) || [];
    if (refinedItems.find(it => it.label === item.label))
      refinedItems = refinedItems.filter(it => it.label !== item.label);
    else refinedItems.push(item);

    let locations = location.search;
    if (locations.includes(attribute)) {
      const locationArray = locations.split('&');
      const filterLocation = locationArray.filter(param => !param.includes(attribute));
      locations = filterLocation.join('&');
    }

    if (refinedItems.length > 0) {
      const refinedItemsString = refinedItems.map(it => it.label).join('|');
      if (locations) {
        history.push(`${locations}&${attribute}=${refinedItemsString}`);
      } else history.push(`?${attribute}=${refinedItemsString}`);
    } else if (locations) {
      history.push(locations);
    } else history.push();
  };

  return (
    <>
      {itemsToShow && itemsToShow.length > 0 && (
        <Paper style={{ marginTop: 24 }}>
          <Typography variant="h6" component="h5" className={classes.title}>
            {label}
          </Typography>
          <FormGroup className={classes.group} spacing={3}>
            {itemsToShow.map(item => (
              <FormControlLabel
                key={item.value}
                control={
                  <Checkbox
                    checked={item.isRefined}
                    className={classes.checkbox}
                    onClick={event => {
                      event.preventDefault();
                      getRefinedItems(item);
                    }}
                    icon={<CheckBoxOutlineBlankOutlinedIcon style={{ color: '#E1E3E2' }} />}
                    checkedIcon={
                      <>
                        <CheckOutlinedIcon
                          style={{ position: 'absolute', color: 'black', left: '6', bottom: '5' }}
                        />
                        <CheckBoxOutlineBlankOutlinedIcon style={{ color: '#E1E3E2' }} />
                      </>
                    }
                  />
                }
                label={
                  <Grid container className={classes.label} spacing={1}>
                    {item.icon && <Grid className={classes.icon}>{item.icon}</Grid>}
                    <Typography
                      variant="subtitle2"
                      className={item.isRefined ? classes.bold : classes.regular}
                    >
                      {item.labelToShow || item.label} ({item.count})
                    </Typography>
                  </Grid>
                }
              />
            ))}
          </FormGroup>
          {!showAll && showMore && items.slice(0, limit)?.length > itemsToShow?.length && (
            <Grid style={{ width: '100%', textAlign: 'center' }}>
              <IconButton
                onClick={() => setShowAll(true)}
                aria-label="More"
                aria-haspopup="true"
                className={classes.iconButton}
                size="small"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          )}
        </Paper>
      )}{' '}
    </>
  );
};

const CustomRefinementList = connectRefinementList(RefinementList);

export default CustomRefinementList;
