import { Images } from '../../Themes';

const community1 = {
  banner_url: `${Images.community.community1}`,
  link_url: 'https://www.lascalaa.fr',
  title: "Maladie d'Alzheimer",
  category: 'Science cognitive',
  color: '#00A19E',
  description:
    'Une communauté d’aidants et de spécialistes de la maladie pour s’entraider, échanger sur l’évolution de la pathologie, partager des trucs et astuces...',
};
const community2 = {
  banner_url: `${Images.community.community2}`,
  link_url: 'https://www.lascalaa.fr',
  title: 'Femme ET Aidante',
  category: 'Style de vie',
  color: '#DF8100',
  description:
    'Aidante, conjointe, maman, femme… comment concilier tous ces rôles, déculpabiliser et prendre du temps pour soi.',
};
const community3 = {
  banner_url: `${Images.community.community3}`,
  link_url: 'https://www.lascalaa.fr',
  title: 'Bipolaire',
  category: 'Science cognitive',
  color: '#097876',
  description:
    'Comprendre les troubles bipolaires, adapter sa vie de famille, communiquer avec des professionnels et des pairs...',
};
const community4 = {
  banner_url: `${Images.community.community4}`,
  link_url: 'https://www.lascalaa.fr',
  title: 'Aidants salariés',
  category: 'Developpement personnel',
  color: '#7F2B33',
  description:
    'Partager des problématiques d’aidants en activité professionnelle, savoir comment en parler à son employeur, être aidé dans les démarches...',
};
const community5 = {
  banner_url: `${Images.community.community5}`,
  link_url: 'https://www.lascalaa.fr',
  title: 'Maladie neurodégénérative',
  category: 'Science cognitive',
  color: '#216750',
  description:
    'Être accompagné par des pairs pour accepter la pathologie de son proche, adapter son rythme de vie, comprendre les réactions de son proche...',
};
export default {
  community1,
  community2,
  community3,
  community4,
  community5,
};
