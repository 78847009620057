import React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardOrganisationResult from '../../Organisations/Shared/CardOrganisationResultMain';
import CardTask from '../../Organisations/Shared/CardTask';
import CardArticle from '../Articles/CardArticle';
import images from '../../../Themes/Images';
import CommunityCard from '../../Block/CommunityCard';
import fakeCommunity from '../../../PagesLeagues/Wello/fake-community';
import { compareHits, getBboxData, getRankingScore } from '../../../Services/AlgoliaHelper';
import { getTwigEndDate } from '../../../Services/DataHelper';
import CardOrganisationTransport from '../../Organisations/Shared/CardOrganisationTransport';
import CardCourse from '../Courses/CardCourse';
import CardCommunity from '../../Communities/CardCommunity';
import { isMobile } from '../../Layout/Styles/LayoutStyles';

type HitsOrgaProps = {
  hits: Array,
  selectedHit: Object,
  refProp: Object,
  onCardClick: Function,
  bbox: Array,
  titleNode: Node,
  isFilteringEnough: Boolean,
  showHighlightedOrga: Boolean,
};

type HitsTwigProps = {
  hits: Array,
  selectedHit: Object,
  refProp: Object,
  titleNode: Node,
  hideTaskInfo: Boolean,
  rowItemSM: Number,
  bbox: Array,
};

type HitOrgaProps = {
  hit: Object,
  onClick: Function,
  isSelected: Boolean,
  isClose: Boolean,
};

type HitTwigProps = {
  hit: Object,
  hideTaskInfo: Boolean,
  rowItemSM: Number,
};

type HitProps = {
  hit: Object,
};

type HitsProps = {
  hits: Array,
  t: Function,
};

type ResultsProps = {
  children: Object,
  isSearchStalled: Boolean,
};

const useStyles = makeStyles(theme => ({
  itemContainer: {
    paddingBottom: 15,
  },
  communityContainer: {
    marginLeft: '2rem',
    marginRight: '2rem',
    paddingBottom: '2rem',
  },
  rootCommunity: {
    padding: theme.spacing(1, 1),
  },
}));

/* Organisations */

function HitOrganisation({ hit, onClick, isSelected, isClose }: HitOrgaProps) {
  const classes = useStyles();
  let organisation = {
    id: hit.objectID,
    name: hit.a_name || '',
    description: hit.a_description || '',
    short_description: hit.a_short_description || '',
    slug: hit.a_slug,
    sampled_helpers: hit.a_sampled_helpers,
    type: hit.a_type,
    helper_count: hit.a_helper_count,
    holding_slug: hit.a_holding_slug,
    holding: {
      id: 57,
      league_id: 1,
      name: hit.a_holding_name || hit.a_holding_name_abbrev || '',
      cover_url: hit.a_banner_ul,
      phone: hit.a_phone,
      slug: hit.a_holding_slug,
      locality: hit.a_locality,
      formatted_address: hit.a_address,
      // eslint-disable-next-line no-underscore-dangle
      coordinates: { latitude: hit._geoloc?.lat, longitude: hit._geoloc?.lng },
      algolia_id: hit.objectID,
      siret: hit.a_siret,
    },
    isNotReferenced: !hit.a_is_wello,
    establishment: {
      short_name: hit.a_establishment?.short_name,
    },
  };

  if (hit.a_is_wello) {
    organisation = {
      ...organisation,
      banner_url: hit.a_banner,
      avatar_url: hit.a_avatar,
      formatted_address: hit.a_formatted_address,
      services: hit.a_service?.map(s => ({ service: s })),
    };
  } else {
    organisation = {
      ...organisation,
      banner_url: images.app.team,
      avatar_url: images.app.team,
      formatted_address: hit.a_address,
    };
  }
  return (
    <Grid item className={classes.itemContainer} key={`grid-${organisation.id}`}>
      <CardOrganisationResult
        key={`organisation-${organisation.id}`}
        organisation={organisation}
        bannerUrl={organisation.banner_url}
        avatarUrl={organisation.avatar_url}
        name={organisation.name}
        holdingName={organisation.holding.name}
        description={organisation.short_description}
        isOnline
        onClick={onClick}
        elevation={isSelected ? 15 : 1}
        backgroundColor={isClose ? '#5E2677' : null}
      />
    </Grid>
  );
}

function HitsOrganisation({
  hits,
  selectedHit,
  refProp,
  onCardClick,
  bbox,
  titleNode,
  isFilteringEnough,
  showHighlightedOrga,
}: HitsOrgaProps) {
  const { t } = useTranslation();
  const previousHits = React.useRef();
  const previousBbox = React.useRef();

  // eslint-disable-next-line
  refProp.current = hits.reduce((acc, value) => {
    acc[value.objectID] = React.createRef();
    return acc;
  }, {});

  const [cityPolygon, cityArea] = React.useMemo(() => {
    if (!bbox) return [null, null];
    return getBboxData(bbox);
  }, [bbox]);

  let orderedHits = previousHits.current?.slice() || hits?.slice();
  if (
    hits &&
    bbox &&
    (bbox !== previousBbox.current ||
      !previousHits.current ||
      !compareHits(previousHits.current, hits))
  ) {
    orderedHits = orderBy(
      hits.map(item => ({
        ...item,
        rank: getRankingScore(
          item.a_polygon_geoloc,
          // eslint-disable-next-line no-underscore-dangle
          item.a_marker_geoloc || item._geoloc,
          bbox,
          cityPolygon,
          cityArea,
        ),
      })),
      'rank',
      'desc',
    );
    previousHits.current = orderedHits;
    previousBbox.current = bbox;
  }

  const hasCloseHits = orderedHits.filter(hit => hit.rank >= 1)?.length > 0;
  const closeHits = hasCloseHits ? [orderedHits[0]] : [];
  const distantHits = orderedHits.slice();
  if (hasCloseHits) distantHits.shift();

  return (
    <>
      {!orderedHits || (orderedHits.length === 0 && t('APP.SEARCH_RESULT_PAGE.NO_ORGANISATIONS'))}
      {!showHighlightedOrga && orderedHits && orderedHits.length > 0 && (
        <>
          {titleNode}
          {orderedHits.map(hit => (
            <div ref={refProp.current[hit.objectID]} key={`hit-${hit.objectID}`}>
              <HitOrganisation
                hit={hit}
                onClick={() => onCardClick(hit)}
                key={hit.objectID}
                isSelected={selectedHit && hit.objectID === selectedHit.objectID}
              />
            </div>
          ))}
        </>
      )}

      {bbox && orderedHits && orderedHits.length > 0 && showHighlightedOrga && (
        <>
          {titleNode}
          {/* closeHits?.length > 0 && titleNode
          // <Typography variant="h4" style={{ paddingBottom: 24 }}>
          //   {t('APP.SEARCH_RESULT_PAGE.CLOSE_ORGANISATIONS')}
          // </Typography>
          // */}
          {isFilteringEnough &&
            closeHits.map(hit => (
              <div ref={refProp.current[hit.objectID]} key={`hit-${hit.objectID}`}>
                <HitOrganisation
                  hit={hit}
                  onClick={() => onCardClick(hit)}
                  key={hit.objectID}
                  isSelected={selectedHit && hit.objectID === selectedHit.objectID}
                  isClose
                />
              </div>
            ))}
          {closeHits?.length > 0 && distantHits?.length > 0 && distantHits && (
            <Typography variant="h4" component="h2" style={{ paddingBottom: 24, paddingTop: 80 }}>
              {t('APP.SEARCH_RESULT_PAGE.DISTANT_ORGANISATIONS')}
            </Typography>
          )}
          {!isFilteringEnough &&
            closeHits.map(hit => (
              <div ref={refProp.current[hit.objectID]} key={`hit-${hit.objectID}`}>
                <HitOrganisation
                  hit={hit}
                  onClick={() => onCardClick(hit)}
                  key={hit.objectID}
                  isSelected={selectedHit && hit.objectID === selectedHit.objectID}
                />
              </div>
            ))}
          {distantHits.map(hit => (
            <div ref={refProp.current[hit.objectID]} key={`hit-${hit.objectID}`}>
              <HitOrganisation
                hit={hit}
                onClick={() => onCardClick(hit)}
                key={hit.objectID}
                isSelected={selectedHit && hit.objectID === selectedHit.objectID}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
}

export const CustomHitsOrganisation = connectHits(HitsOrganisation);

/* Twigs */

export function HitTwig({ hit, hideTaskInfo, rowItemSM }: HitTwigProps) {
  const task = {
    slug: hit.a_slug,
    excerpt: hit.a_excerpt,
    start_date: hit.a_event_date,
    until_date: hit.a_until_date,
    end_date: getTwigEndDate({
      task: { start_date: hit.a_start_date, end_date: hit.a_end_date },
      event_date: hit.a_event_date,
    }),
    cover_url: hit.a_cover_url || '',
    description: hit.a_description,
    holding_slug: hit.a_holding_slug,
    organisation_slug: hit.a_organisation_slug,
    organisation_name: hit.a_organisation_name,
    holding_name: hit.a_holding_name,
    expertise: hit.a_expertise,
    id: hit.a_task_id,
    formatted_address: hit.a_formatted_address,
    // eslint-disable-next-line no-underscore-dangle
    coordinates: hit._geoloc,
    registration: hit.a_registration,
    max_participants: hit.a_max_participants,
    occurrences: hit.a_occurrences,
    recurrence_type: hit.a_recurrence_type,
    recurrence_interval: hit.a_recurrence_interval,
    twig_for_date: {
      event_start_time: hit.a_event_start_time,
      event_end_time: hit.a_event_end_time,
    },
  };

  return (
    <Grid item md={rowItemSM || 4}>
      <CardTask task={task} showIsOver isReferenced hideTaskInfo={hideTaskInfo} />
    </Grid>
  );
}

function HitsTwig({
  hits,
  selectedHit,
  refProp,
  titleNode,
  hideTaskInfo,
  rowItemSM,
  bbox,
}: HitsTwigProps) {
  const { t } = useTranslation();
  const previousHits = React.useRef();
  const previousBbox = React.useRef();

  // eslint-disable-next-line
  refProp.current = hits.reduce((acc, value) => {
    acc[value.objectID] = React.createRef();
    return acc;
  }, {});

  const [cityPolygon, cityArea] = React.useMemo(() => {
    if (!bbox) return [null, null];
    return getBboxData(bbox);
  }, [bbox]);

  let orderedHits = previousHits.current?.slice() || hits?.slice();
  if (
    hits &&
    bbox &&
    (bbox !== previousBbox.current ||
      !previousHits.current ||
      !compareHits(previousHits.current, hits))
  ) {
    orderedHits = orderBy(
      hits.map(item => ({
        ...item,
        rank: getRankingScore(
          item.a_polygon_geoloc,
          // eslint-disable-next-line no-underscore-dangle
          item.a_marker_geoloc || item._geoloc,
          bbox,
          cityPolygon,
          cityArea,
        ),
      })),
      'rank',
      'desc',
    );
    previousHits.current = orderedHits;
    previousBbox.current = bbox;
  }

  return (
    <>
      {!orderedHits || orderedHits.length === 0 ? (
        t('APP.SEARCH_RESULT_PAGE.NO_TWIGS')
      ) : (
        <>
          {titleNode}
          <Grid container spacing={2}>
            {orderedHits.map(hit => {
              if (selectedHit && hit.objectID === selectedHit.objectID) {
                return (
                  <HitTwig
                    hit={hit}
                    key={hit.objectID}
                    hideTaskInfo={hideTaskInfo}
                    rowItemSM={rowItemSM}
                  />
                );
              }
              return (
                <HitTwig
                  hit={hit}
                  key={hit.objectID}
                  hideTaskInfo={hideTaskInfo}
                  rowItemSM={rowItemSM}
                />
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
}

export const CustomHitsTwig = connectHits(HitsTwig);

/* Deals */

function HitArticle({ hit }: HitProps) {
  const classes = useStyles();

  const article = {
    slug: hit.a_slug,
    content: hit.a_content,
    title: hit.a_title,
    related_words: hit.a_related_words,
    created_at: hit.a_created_at,
    updated_at: hit.a_updated_at,
    cover_image_url: hit.a_cover_image_url,
    visibility: hit.a_visibility,
    postable_name: hit.a_postable_name,
    postable_type: hit.a_postable_type,
  };

  return (
    <Grid className={classes.itemContainer}>
      <CardArticle
        article={article}
        canEdit={false}
        linkUrl={`/articles/${article.slug}?goBack=search`}
        showPostable
      />
    </Grid>
  );
}

const HitsArticle = ({ hits, t }: HitsProps) => (
  <>
    {!hits || hits.length === 0
      ? t('APP.SEARCH_RESULT_PAGE.NO_ARTICLE')
      : hits.map(hit => {
          return <HitArticle hit={hit} key={hit.objectID} />;
        })}
  </>
);

export const CustomHitsArticle = connectHits(withTranslation()(HitsArticle));

/* Courses */

function HitCourse({ hit }: HitProps) {
  const course = {
    id: hit.objectID,
    description: hit.a_description,
    title: hit.a_title,
    cover_image_url: hit.a_cover_image_url,
    duration: hit.a_duration,
    postable_name: hit.a_postable_name,
    postable_id: hit.a_postable_id,
    postable_slug: hit.a_postable_slug,
    slug: hit.a_slug,
  };

  return (
    <Grid item lg={6} key={hit.objectID} style={{ flexGrow: 1 }}>
      <CardCourse course={course} linkUrl={`/formations/${course.slug}?goBack=search`} />
    </Grid>
  );
}

const HitsCourse = ({ hits, t }: HitsProps) => (
  <>
    {!hits || hits.length === 0 ? (
      t('APP.SEARCH_RESULT_PAGE.NO_COURSES')
    ) : (
      <Grid container spacing={2}>
        {hits.map(hit => {
          return <HitCourse hit={hit} key={hit.objectID} />;
        })}
      </Grid>
    )}
  </>
);

export const CustomHitsCourse = connectHits(withTranslation()(HitsCourse));

/* transport */

function HitTransport({ hit }: HitProps) {
  const organisation = {
    banner_url: hit.a_banner,
    avatar_url: hit.a_avatar,
    formatted_address: hit.a_formatted_address,
    services: hit.a_service?.map(s => ({ service: s })),
    id: hit.objectID,
    name: hit.a_name || '',
    description: hit.a_description || '',
    short_description: hit.a_short_description || '',
    slug: hit.a_slug,
    sampled_helpers: hit.a_sampled_helpers,
    type: hit.a_type,
    helper_count: hit.a_helper_count,
    holding_slug: hit.a_holding_slug,
    holding: {
      id: 57,
      league_id: 1,
      name: hit.a_holding_name || hit.a_holding_name_abbrev || '',
      cover_url: hit.a_banner_ul,
      phone: hit.a_phone,
      slug: hit.a_holding_slug,
      locality: hit.a_locality,
      formatted_address: hit.a_address,
      // eslint-disable-next-line no-underscore-dangle
      coordinates: { latitude: hit._geoloc?.lat, longitude: hit._geoloc?.lng },
      algolia_id: hit.objectID,
      siret: hit.a_siret,
    },
    isNotReferenced: !hit.a_is_wello,
    establishment: {
      short_name: hit.a_establishment?.short_name,
    },
  };

  return (
    <Grid item sm={6} md={3} key={hit.objectID}>
      <CardOrganisationTransport
        key={`organisation-${organisation.id}`}
        organisation={organisation}
        bannerUrl={organisation.banner_url}
        avatarUrl={organisation.avatar_url}
        city={organisation.locality}
        name={organisation.name}
        holdingName={organisation?.holding.name || ''}
        description={organisation.short_description?.replace(/<[^>]*>/g, '') || ''}
        hidePrice
        // disabled={organisation.disabledMode}
        // addressFrom={form?.addressFrom}
        // addressTo={form?.addressTo}
      />
    </Grid>
  );
}

const HitsTransport = ({ hits, t }: HitsProps) => (
  <>
    {!hits || hits.length === 0 ? (
      t('APP.SEARCH_RESULT_PAGE.NO_ORGANISATIONS')
    ) : (
      <Grid container spacing={2}>
        {hits.map(hit => {
          return <HitTransport hit={hit} key={hit.objectID} />;
        })}
      </Grid>
    )}
  </>
);

export const CustomHitsTransport = connectHits(withTranslation()(HitsTransport));

const StateResults = ({ children, isSearchStalled }: ResultsProps) => {
  return (
    <Grid style={{ width: '100%' }} hidden={isSearchStalled}>
      {children}
    </Grid>
  );
};

export const CustomStateResults = connectStateResults(StateResults);

export const CustomClubs = () => {
  const classes = useStyles();

  const settings = {
    arrows: !isMobile,
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 5,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Grid className={classes.communityContainer}>
      <Slider {...settings}>
        <Paper className={classes.rootCommunity} elevation={0}>
          <CommunityCard community={fakeCommunity.community1} />
        </Paper>
        <Paper className={classes.rootCommunity} elevation={0}>
          <CommunityCard community={fakeCommunity.community2} />
        </Paper>
        <Paper className={classes.rootCommunity} elevation={0}>
          <CommunityCard community={fakeCommunity.community3} />
        </Paper>
        <Paper className={classes.rootCommunity} elevation={0}>
          <CommunityCard community={fakeCommunity.community4} />
        </Paper>
        <Paper className={classes.rootCommunity} elevation={0}>
          <CommunityCard community={fakeCommunity.community5} />
        </Paper>
      </Slider>
    </Grid>
  );
};

/* Communities */

function HitCommunity({ hit }: HitProps) {
  const community = {
    id: hit.objectID,
    name: hit.a_name,
    short_description: hit.a_short_description,
    holding_cover_url: hit.a_holding_cover_url,
    helper_count: hit.a_helper_count,
    visibility: hit.a_visibility,
    slug: hit.a_slug,
  };

  return (
    <Grid item xs={12} sm={6} md={3} key={hit.objectID} style={{ flexGrow: 1 }}>
      <CardCommunity community={community} />
    </Grid>
  );
}

const HitsCommunity = ({ hits }: HitsProps) => (
  <>
    {!hits || hits.length === 0 ? (
      // t('APP.SEARCH_RESULT_PAGE.NO_COURSES')
      <CustomClubs />
    ) : (
      <Grid container spacing={2}>
        {hits.map(hit => {
          return <HitCommunity hit={hit} key={hit.objectID} />;
        })}
      </Grid>
    )}
  </>
);

export const CustomHitsCommunity = connectHits(withTranslation()(HitsCommunity));
