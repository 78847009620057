import React from 'react';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const styles = () => ({
  card: {
    height: '350px',
  },
  media: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
  avatar: {
    display: 'inline-block',
    width: 42,
    height: 42,
  },
  textArea: {
    height: '150px',
    alignItems: 'flex-start',
  },
  text: {
    marginTop: 7,
    color: 'white',
    marginBottom: 10,
    height: '50px',
  },
  desc: {
    color: 'white',
    height: '100px',
  },
  buttonSection: {
    height: '55px',
    paddingTop: '15px',
  },
  button: {
    borderColor: '#fff',
    borderWidth: '0.5px',
  },
  buttonText: {
    color: 'white',
  },
  actionArea: {
    height: '100%',
    width: '100%',
    padding: '10px',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 100%)',
  },
  chipArea: {
    height: '120px',
  },
  chip: {
    marginLeft: '0px',
    marginRight: '3px',
    marginBottom: '3px',
    height: '20px',
    color: 'white',
    fontWeight: '700',
  },
});

type Props = {
  community: Object,
  classes: Object,
  // t: Function,
};

function CommunityCard({ community, classes }: Props) {
  return (
    <Card className={classes.card}>
      <CardMedia
        alt={community.title}
        className={classes.media}
        image={community.banner_url}
        title={community.title}
      >
        <Grid container className={classes.actionArea}>
          <Grid className={classes.chipArea}>
            <Chip
              className={classes.chip}
              label={community.category}
              style={{
                backgroundColor: community.color,
              }}
            />
          </Grid>
          <Grid container align="left" alignItems="flex-end" className={classes.textArea}>
            <Typography variant="h4" component="h3" className={classes.text}>
              {community.title}
            </Typography>
            <Typography variant="caption" className={classes.desc}>
              {community.description}
            </Typography>
          </Grid>
          {/* <Grid container className={classes.buttonSection}>
            <Button variant="outlined" size="small" fullWidth className={classes.button}>
              <Typography className={classes.buttonText}>{t('JOIN')}</Typography>
            </Button>
          </Grid> */}
        </Grid>
      </CardMedia>
    </Card>
  );
}
export default withStyles(styles)(withTranslation()(CommunityCard));
