import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import CircleIcon from '@mui/icons-material/Circle';
import { green, orange } from '@material-ui/core/colors';
import CardActions from '@material-ui/core/CardActions';
import CheckIcon from '@mui/icons-material/Check';
import PauseIcon from '@mui/icons-material/Pause';

import ConfirmationDialog from '../ConfirmationDialog';

type Props = {
  course: Object,
  canEdit: Boolean,
  linkUrl: String,
  onDelete: Function,
  editUrl: String,
};

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
  },
  cardAction: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  img: {
    width: 284,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  cardContent: {
    padding: 24,
    flexGrow: 1,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '4',
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  menu: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
    [theme.breakpoints.only('xs')]: {
      top: 280,
    },
  },
  gridWidth: {
    width: 'auto',
  },
}));

function CardCourse({ course, canEdit, linkUrl, onDelete, editUrl }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState();
  const [openDestroyCourseDiag, setOpenDestroyCourseDiag] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const handleClickConfirmDestroyCourseDiag = () => {
    onDelete(course);

    setOpenDestroyCourseDiag(false);
    setAnchorEl(null);
  };

  const handleClickCancelDestroyCourseDiag = () => {
    setOpenDestroyCourseDiag(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea className={classes.cardAction} onClick={() => history.push(`${linkUrl}`)}>
          <CardMedia
            component="img"
            height="284"
            className={classes.img}
            image={course.cover_image_url}
            alt={course.title}
          />
          <CardContent className={classes.cardContent}>
            {canEdit && (
              <Grid container style={{ alignItems: 'center', marginBottom: 8 }}>
                <CircleIcon
                  style={{
                    color: course.state === 'pending' ? orange[600] : green[600],
                    fontSize: 'small',
                    marginRight: 8,
                  }}
                />
                <Typography variant="h5" component="div">
                  {t(`APP.COURSES.STATE.${course.state.toUpperCase()}`)}
                </Typography>
              </Grid>
            )}
            <Grid class="p-name">
              <Typography variant="h4" className={classes.title}>
                {course.title}
              </Typography>
            </Grid>
            <Grid class="e-content">
              <Typography
                variant="caption"
                className={classes.description}
                dangerouslySetInnerHTML={{ __html: course.description }}
              />
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <AccessTimeIcon className={classes.icon} />
              <Typography variant="caption" className={classes.marginRight}>
                {course.duration}
              </Typography>
              <Grid container alignItems="center" className={classes.gridWidth}>
                <PeopleIcon className={classes.icon} />
                <Chip label={course.postable_name} className={classes.marginRight} />
              </Grid>
              {course.progression && course.progression !== 'uninitiated' && (
                <Grid container alignItems="center" className={classes.gridWidth}>
                  {course.progression === 'terminated' ? (
                    <CheckIcon className={classes.icon} />
                  ) : (
                    <PauseIcon className={classes.icon} />
                  )}
                  <Typography variant="caption">
                    {t(`APP.COURSES.PROGRESSION.${course.progression}`)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </CardActionArea>
        {canEdit && (
          <Grid className={classes.menu}>
            <CardActions>
              <IconButton
                onClick={event => setAnchorEl(event.currentTarget)}
                aria-label="More"
                aria-owns={anchorEl ? 'actions-menu' : undefined}
                aria-haspopup="true"
              >
                <MoreVertIcon />
              </IconButton>
            </CardActions>
            <Menu
              id="actions-menu"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => history.push(`${editUrl || linkUrl}/edit`)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText>{t('EDIT')}</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => setOpenDestroyCourseDiag(true)}>
                <ListItemIcon>
                  <DeleteForeverIcon />
                </ListItemIcon>
                <ListItemText>{t('DELETE')}</ListItemText>
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Card>

      <ConfirmationDialog
        openDialog={openDestroyCourseDiag}
        onCancel={handleClickCancelDestroyCourseDiag}
        onClickConfirm={handleClickConfirmDestroyCourseDiag}
        dialogTitle={t('APP.COURSES.DELETE.CONFIRMATION_MESSAGE')}
      />
    </>
  );
}

export default CardCourse;
