import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';

const useStyle = makeStyles(theme => ({
  card: {
    position: 'relative',
  },
  media: {
    height: 200,
  },
  textContainer: {
    minHeight: 92,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  community: Object,
};

const CardCommunity = ({ community }: Props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea
          onClick={() => history.push(`/communities/${community?.slug}`)}
          className={classes.cardActionArea}
        >
          <CardMedia
            image={community?.holding_cover_url}
            title={community?.name}
            alt={community?.name}
            className={classes.media}
          />
          <CardContent>
            <Grid className={classes.textContainer}>
              <Typography variant="h4" className={classes.title}>
                {community?.name}
              </Typography>
              <Typography
                variant="caption"
                dangerouslySetInnerHTML={{ __html: community?.short_description }}
                className={classes.description}
              />
            </Grid>
            <Grid className={classes.container}>
              <PeopleIcon className={classes.icon} />
              <Typography variant="caption" className={classes.marginRight}>
                {community?.helper_count}
              </Typography>
              <VisibilityIcon className={classes.icon} />
              <Typography variant="caption" className={classes.marginRight}>
                {t(`APP.COMMUNITIES.VISIBILITY.${community?.visibility}`)}
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default CardCommunity;
