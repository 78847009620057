import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from '../ConfirmationDialog';
import Routes from '../../../Routing/Routes';
import { getWithoutHtml } from '../../../Services/ArticleHelper';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
  },
  card: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    paddingLeft: '0px',
    paddingBottom: '0px',
  },
  itemText: {
    paddingRight: theme.spacing(8),
    border: '1px solid #E4E4E4',
    borderRadius: 6,
    padding: theme.spacing(3),
    margin: 0,
    height: '215px',
    overflow: 'hidden',
  },
  listItemText: {
    alignSelf: 'flex-start',
  },
  list: {
    paddingTop: '0px',
  },
  media: {
    minWidth: '180px',
    minHeight: '140px',
    objectFit: 'cover',
    borderRadius: '3px',
  },
  content: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    lineHeight: '1.45',
    whiteSpace: 'normal',
    margin: theme.spacing(2, 0),
  },
  title: {
    textDecoration: 'none',
    fontSize: '1.1em',
  },
  chip: {
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    color: 'white',
    backgroundColor: theme.palette.grey[400],
  },
  dialog: {
    padding: theme.spacing(2),
  },
  contactButton: {
    color: theme.current.primary.main,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

type Props = {
  article: Object,
  canEdit: Boolean,
  linkUrl: String,
  onEditUrl: String,
  onDelete: Function,
  disabled: Boolean,
  showVisibility: Boolean,
  showPostable: Boolean,
};

const CardArticle = ({
  article,
  canEdit,
  linkUrl,
  onDelete,
  onEditUrl,
  disabled,
  showVisibility,
  showPostable,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState();
  const [openDestroyArticleDiag, setOpenDestroyArticleDiag] = React.useState(false);
  const [openPremiumDialog, setOpenPremiumDialog] = React.useState(false);

  const handleClickConfirmDestroyArticleDiag = () => {
    onDelete(article);

    setOpenDestroyArticleDiag(false);
    setAnchorEl(null);
  };

  const handleClickCancelDestroyArticleDiag = () => {
    setOpenDestroyArticleDiag(false);
    setAnchorEl(null);
  };

  const getCaption = () => {
    let caption = moment(article.created_at).format('LLLL');
    if (showVisibility) {
      caption = `${caption} - ${t('APP.ARTICLES.VISIBILITY')}${' '}
      ${t(`APP.ARTICLES.VISIBILITY.${article.visibility?.toUpperCase()}`)?.toLowerCase()}`;
    }
    if (showPostable) {
      caption = `${caption} - ${t(
        article.postable_type === 'League'
          ? 'APP.ARTICLES.LEAGUE.PUBLISHED_BY'
          : 'APP.ARTICLES.HOLDING.PUBLISHED_BY',
      )}${' '}
      ${article.postable_name}`;
    }

    return caption;
  };

  return (
    <>
      <List className={classes.list}>
        <Grid class="h-entry">
          <ListItem
            className={classes.itemText}
            button
            onClick={() => (disabled ? setOpenPremiumDialog(true) : history.push(`${linkUrl}`))}
            // disabled={disabled}
            style={{ opacity: disabled ? 0.5 : 1 }}
          >
            <ListItemText
              className={classes.listItemText}
              primary={
                <Grid class="p-name">
                  <Typography variant="h4" className={classes.title}>
                    {article.title}
                  </Typography>
                  {article.created_at && (
                    <Typography variant="caption" style={{ paddingTop: 8 }}>
                      {getCaption()}
                    </Typography>
                  )}
                </Grid>
              }
              secondary={
                <>
                  <Grid class="e-content">
                    <Typography variant="body2" className={classes.content}>
                      {getWithoutHtml(article.content)}
                    </Typography>
                  </Grid>
                  <Grid>
                    {article.related_words?.map(word => (
                      <Chip key={word} className={classes.chip} label={word} />
                    ))}
                  </Grid>
                </>
              }
            />
            {canEdit && (
              <ListItemSecondaryAction>
                <IconButton
                  onClick={event => setAnchorEl(event.currentTarget)}
                  aria-label="More"
                  aria-owns={anchorEl ? 'actions-menu' : undefined}
                  aria-haspopup="true"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="actions-menu"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => history.push(`${onEditUrl || linkUrl}/edit`)}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>{t('EDIT')}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setOpenDestroyArticleDiag(true)}>
                    <ListItemIcon>
                      <DeleteForeverIcon />
                    </ListItemIcon>
                    <ListItemText>{t('DELETE')}</ListItemText>
                  </MenuItem>
                </Menu>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </Grid>
      </List>
      <ConfirmationDialog
        openDialog={openDestroyArticleDiag}
        onCancel={handleClickCancelDestroyArticleDiag}
        onClickConfirm={handleClickConfirmDestroyArticleDiag}
        dialogTitle={t('APP.ARTICLES.DELETE.CONFIRMATION_MESSAGE')}
      />
      <Dialog
        open={openPremiumDialog}
        onClose={() => setOpenPremiumDialog(null)}
        className={classes.dialog}
      >
        <DialogTitle style={{ alignSelf: 'center', padding: 24, paddingBottom: 8 }}>
          {' '}
          <Typography variant="h4" className={classes.title}>
            {t('APP.ARTICLES.PREMIUM.TITLE')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: 24, paddingTop: 8, paddingBottom: 16 }}>
          {t('APP.ARTICLES.PREMIUM.CONTENT')}
        </DialogContent>
        {Routes.currentNamespace() === 'SLA' && (
          <DialogContent style={{ textAlign: '-webkit-center', padding: 16, paddingTop: 0 }}>
            <Button
              className={classes.contactButton}
              href="mailto:federation@soutenirlesaidants.fr?cc=hello@wello.fr&subject=Adhésion"
            >
              {t('CONTACT_US')}
            </Button>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default CardArticle;
