/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connectGeoSearch } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Images } from '../../../Themes';
import { isClientSide } from '../../../Config/ServerConfig';
import { compareHits, INDEX_NAMES } from '../../../Services/AlgoliaHelper';

const useStyles = makeStyles(theme => ({
  map: {
    height: 350,
    boxShadow: '0px 1px 2px 1px rgb(0 0 0 / 31%)',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      maxHeight: 200,
    },
  },
}));

type Props = {
  hits: Array,
  refine: Function,
  currentRefinement: String,
  selectedHit: Object,
  query: String,
  initialPosition: Object,
  onMarkerClick: Function,
  zoom: Number,
};

const L = isClientSide() ? require('leaflet') : undefined;

const blueIcon = isClientSide() ? (
  new L.Icon({
    iconUrl: `${Images.wello.marker_icon_blue}`,
    shadowUrl: `${Images.wello.marker_icon_shadow}`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })
) : (
  <></>
);

const redIcon = isClientSide() ? (
  new L.Icon({
    iconUrl: `${Images.wello.marker_icon_red}`,
    shadowUrl: `${Images.wello.marker_icon_shadow}`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })
) : (
  <></>
);

const orangeIcon = isClientSide() ? (
  new L.Icon({
    iconUrl: `${Images.wello.marker_icon_orange}`,
    shadowUrl: `${Images.wello.marker_icon_shadow}`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })
) : (
  <></>
);

function GeoSearch({
  hits,
  refine,
  currentRefinement,
  selectedHit,
  query,
  initialPosition,
  onMarkerClick,
  zoom,
}: Props) {
  const classes = useStyles();
  const markers = useRef([]);
  const polygon = useRef(null);
  const firstUpdate = useRef(true);
  const userUtilisation = useRef(true);
  const hasBeenMoved = useRef(false);
  const instance = useRef();
  const el = useRef();
  const currentHits = useRef(hits);
  const position = useRef(initialPosition);
  const [currentSelectedHit, setCurrentSelectedHit] = useState(selectedHit);

  const handleMarkerClick = useCallback(
    e => {
      const selected = hits.find(hit => hit.objectID === e?.target?.options?.alt);
      if (selected) {
        if (currentSelectedHit && currentSelectedHit.objectID === selected.objectID) {
          setCurrentSelectedHit(null);
          onMarkerClick(null);
        } else {
          setCurrentSelectedHit(selected);
          onMarkerClick(selected);
        }
      }
    },
    [currentSelectedHit, hits, onMarkerClick],
  );

  useEffect(() => {
    if (!isClientSide()) return;
    instance.current = L.map(el.current, { scrollWheelZoom: false });
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(instance.current);
    instance.current.on('moveend', () => {
      if (userUtilisation.current) {
        const ne = instance.current.getBounds().getNorthEast();
        const sw = instance.current.getBounds().getSouthWest();

        refine({
          northEast: { lat: ne.lat, lng: ne.lng },
          southWest: { lat: sw.lat, lng: sw.lng },
        });

        hasBeenMoved.current = true;
      }
    });
  }, [refine]);

  useEffect(() => {
    setCurrentSelectedHit(selectedHit);
  }, [selectedHit]);

  useEffect(() => {
    if (!isClientSide()) return;
    markers.current.forEach(marker => marker.remove());
    if (polygon.current) polygon.current.remove();
    markers.current = hits
      .filter(hit => hit.a_marker_geoloc?.lat || hit._geoloc?.lat)
      .map(hit => {
        // eslint-disable-next-line no-underscore-dangle
        const geoloc = hit.a_marker_geoloc || hit._geoloc;
        if (!geoloc || !geoloc.lat || !geoloc.lng) return null;
        if (currentSelectedHit && hit.objectID === currentSelectedHit.objectID) {
          if (Array.isArray(hit.a_polygon_geoloc) && hit.a_polygon_geoloc?.length > 2) {
            polygon.current = L.polygon(
              hit.a_polygon_geoloc.map(g => [g.lat, g.lng]),
              { color: 'blue' },
            ).addTo(instance.current);
          }

          return L.marker([geoloc.lat, geoloc.lng], { icon: redIcon, alt: hit.objectID })
            .addTo(instance.current)
            .on('click', handleMarkerClick);
        }
        if (hit.index_name && hit.index_name === INDEX_NAMES.TASK) {
          return L.marker([geoloc.lat, geoloc.lng], { icon: orangeIcon, alt: hit.objectID })
            .addTo(instance.current)
            .on('click', handleMarkerClick);
        }
        return L.marker([geoloc.lat, geoloc.lng], { icon: blueIcon, alt: hit.objectID })
          .addTo(instance.current)
          .on('click', handleMarkerClick);
      });

    if ((hits && hits.length === 0) || !compareHits(currentHits.current, hits)) {
      if (
        (!currentRefinement || firstUpdate.current) &&
        markers.current.length &&
        !initialPosition
      ) {
        userUtilisation.current = false;
        instance.current.fitBounds(L.featureGroup(markers.current).getBounds(), {
          animate: false,
        });
        userUtilisation.current = true;
        firstUpdate.current = false;
      } else if (!currentRefinement) {
        instance.current.setView(
          initialPosition && initialPosition.lat && initialPosition.lng
            ? initialPosition
            : {
                lat: 48.864716,
                lng: 2.349014,
              },
          initialPosition ? zoom || 9 : 4,
          {
            animate: false,
          },
        );
      }
      currentHits.current = hits;
    }
  }, [
    hits,
    currentSelectedHit,
    selectedHit,
    currentRefinement,
    initialPosition,
    handleMarkerClick,
  ]);

  useEffect(() => {
    if (!isClientSide()) return;
    // Reset
    if (
      hasBeenMoved &&
      position.current?.lat === initialPosition?.lat &&
      position.current?.lng === initialPosition?.lng
    )
      return;
    position.current = initialPosition;
    userUtilisation.current = false;
    instance.current.setView(
      position.current && position.current?.lat && position.current?.lng
        ? position.current
        : {
            lat: 48.864716,
            lng: 2.349014,
          },
      position.current ? zoom || 9 : 4,
      {
        animate: false,
      },
    );
    const ne = instance.current.getBounds().getNorthEast();
    const sw = instance.current.getBounds().getSouthWest();

    refine({
      northEast: { lat: ne.lat, lng: ne.lng },
      southWest: { lat: sw.lat, lng: sw.lng },
    });

    userUtilisation.current = true;
    firstUpdate.current = true;
  }, [refine, query, initialPosition]);

  return isClientSide() ? (
    <div>
      <div className={classes.map} ref={el} />
      {Boolean(currentRefinement)}
    </div>
  ) : (
    <></>
  );
}

const CustomGeoSearch = connectGeoSearch(GeoSearch);

export default CustomGeoSearch;
